@keyframes open {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(100%);
  }
}
