.logo {
  grid-area: logo;
  width: 2.5rem;
  height: 3.5rem;
  margin-block-start: -.5em;
}

.logo__img {
  width: 2.5rem;
  height: 3.5rem;
}
