#trigger-big-image-dialog {
  background-color: transparent;
  border-color: transparent;
  opacity: .5;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0;
  cursor: zoom-in;
}

.img-container {
  position: relative;
}

.product-facts {
  list-style: square;
  margin-inline-start: 1rem;
  padding-inline-start: 0;
  line-height: 1.5;

  li {
    margin-inline-start: .5rem;
  }
}

.product__disclosure,
.product__size-switcher {
  margin-block-end: 1.5em;
}

.product__properties {
  display: grid;
  gap: .5rem;
  grid-template-columns: 25% 75%;
  margin-inline-start: 1rem;


  dt {
    font-weight: bold;
  }

  dd {
    padding-inline-start: 0;
    margin-inline-start: 1rem;
  }
}

.product__table {
  margin: 1rem;

  td {
    padding: .5rem .5rem .5rem 0;
  }

  th {
    padding-inline-end: 1rem;
    text-align: left;
  }
}

.product-order-button {
  font-size: 1.1rem;
}

.product__image {
  max-width: 100%;
}

.product-variations--size {
  border: none;
  padding: 0;
  margin-block-end: 2rem;

  input {
    opacity: 0;
    position: absolute;
    inset: 0;

    &:checked ~ label {
      background-color: #000;
      color: white;
    }

    &:focus-visible ~ label {
      border-width: 2px;
    }
  }

  label {
    border: 1px solid #000;
    border-radius: 10px;
    display: inline-block;
    min-width: 2rem;
    padding: 0 .25rem;
    margin-inline-end: .5em;
    font-weight: bold;
  }
}

.product-variations--size__heading,
.product-variations--color__heading {
  letter-spacing: 0;
  font-size: 1rem;
}

.product-variations--size__item {
  position: relative;
  display: inline-block;
  margin-block-end: .5rem;
}

.product-variations--color {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
  margin-block-end: 2rem;
}

.product-variations--color__item {
  max-width: 4rem;
  max-height: 4rem;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
  }
}
