:root {
  --brand-red: #B1004A;
  --brand-blue: #1E344E;
  --brand-white: #FFF;
  --brand-lightgrey: #FAFBFC;
  --brand-grey: #E5E7EB ;
  --border-radius: 6px;
  --combobox-input-height: 3rem;
  --combobox-border-radius: var(--border-radius);
  --combobox-input-width-min: 30rem;
  --combobox-input-width-min-medium: 18rem;
  --combobox-input-width-max: 50rem;
}
