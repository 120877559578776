.form {

  &__error {
    grid-area: error;
    margin: .5em 0;
    background-color: rgb(120, 2, 2);
    padding: .25em;
    color: var(--brand-white);
  }

  &__password-show-wrapper {
    position: relative;
  }

  &__input-box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:  "error error"
                          "label input";
    margin-block-start: 1rem;

    @media (max-width: 40em) {
      grid-template-columns: 1fr;
      grid-template-areas: "label"
                        "input"
                        "error";
    }
  }

  &__label {
    grid-area: label;

    @media (max-width: 40em) {
      margin-block-end: .5rem;
    }
  }


  input[type="text"], input[type="number"], input[type="email"], input[type="password"] {
    grid-area: input;
    height: 1.5em;
    padding: .25em;
    font: inherit;
    border: 1px solid var(--brand-blue);
    background-color: transparent;
  }

  input[type="radio"], input[type="checkbox"] {
    accent-color: var(--brand-blue);
    border: 1px solid var(--brand-blue);
    background-color: transparent;
  }
}
