.cart-widget-button {
  grid-area: cardbutton;
  position: relative;

  @media (max-width: 60em) {
    padding: 0 2rem 0 0;
  }

  @media (max-width: 40em) {
    padding: 0 0 0 0;
    margin-inline-end: 2em;
  }

  @media (max-width: 25em) {
    padding: 0 0 0 0;
    margin-inline-end: 0;
  }

  &__total-cart-items {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: -.25rem;
    background-color: var(--brand-red);
    border-radius: 50%;
    color: var(--brand-white);
    font-weight: bold;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border: 1px solid transparent;

    @media (max-width: 40em) {
      top: -1.35rem;
      right: -1em;
    }

    @media (max-width: 30em) {
      top: -1.35rem;
      right: -2em;
    }
  }

}
