/* Folgendes ist ein nur leicht angepasstes https://www.w3.org/WAI/tutorials/carousels/full-code/
unter https://www.w3.org/WAI/about/using-wai-material/ */

.active .slide {
  display: block !important;
  visibility: hidden;
  transition: left .6s ease-out;
}

.active .slide.current {
  visibility: visible;
  left: 0;
  margin-block-end: 0;

}

.active .slide.next {
  left: 100%;
}

.active .slide.prev {
  left: -100%;
}

.active .slide.next.in-transition,
.active .slide.prev.in-transition {
  visibility: visible;
}


.carousel, .slide {
  padding:0;
  margin: 0;
  overflow: hidden;
}
.carousel {
  position: relative;
  z-index: 1;

}
.carousel ul {
  margin:0;
  padding: 0;
  list-style: none;
}
.slide {
  height: 360px;
  width: 100%;
  background-size: cover;
  position: relative;
  margin-block-end:1em;
  border:1px solid #333;
  overflow: visible;
}
.slide h2 {
  display:inline-block;
  font-size: 1.75em;
  margin: 1rem;
  background-color: var(--brand-blue);
  color: var(--brand-white);
  padding: .225em .5em;
  line-height: 1.5;
  float: left;

  @media (max-width: 40em) {
    font-size: 1.1em;
  }
}
.slide p {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 2rem;
  margin:0;
  clear:both;
  padding: 1rem;
  line-height: 1.5;
  background-color: rgba(255,255,255,.8);
  border-radius: var(--border-radius);

  @media (max-width: 40em) {
    left: 1rem;
    right: 1rem;
    font-size: 87%;
  }
}
.slide a {
  display:block;
  text-align: right;
}

.carousel.active {
  height: 27rem;
  position: relative;

  @media (max-width: 40em) {
    height: 21rem;
  }
}

.active .slide {
  border: none;
  display: none;
  position:absolute;
  top:0;
  left:0;
  z-index:20;
}

.slide.current {
  display: block;
  z-index: 50;
  position: relative;
}

.slide p {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;

  @media (max-width: 40em) {
    gap: .5rem;
  }
}

.slide a {
  display: inline-block;
  background-color: var(--brand-red);
  margin-block-start: .5rem;
  padding: .25em  .75em;
  color: var(--brand-white);
  text-decoration: none;

}

.btn-prev,
.btn-next {
  position:absolute;
  z-index: 70;
  top: calc(100% - .82rem);
  margin-block-start: -2.5em;
  border :0;
  background: rgba(255,255,255,.6);
  line-height: 1;
  padding:10px 5px;
  transition: padding .4s ease-out;

  @media (max-width: 40em) {
    top: 33%;
  }
}

.btn-next:hover,
.btn-next:focus,
.btn-prev:hover,
.btn-prev:focus {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}

.btn-prev {
  left:0;
}

.btn-next {
  right:0;
}

.carousel.with-slidenav {
  padding-block-end: 3em;
  margin-block-end: 2rem;
}


.slidenav {
  position: absolute;
  bottom: .75rem;
  left: 4rem;
  right: 4rem;
  z-index: 200;

  @media (max-width: 40em) {
    display: flex;
    justify-content: center;
  }
}

.slidenav li {
  display:inline-block;
  margin: 0 .5em;
}

.slidenav button {
  border: 2px solid var(--brand-blue);
  background-color: var(--brand-blue);
  line-height: 1em;
  height: 1.5em;
  width: auto;
  font: inherit;
  font-weight: bold;
  font-size: 87%;
  line-height: 1.2;
  color: var(--brand-white);
  outline: 2px solid rgba(255, 255, 255, .5);
}

.slidenav button {
  border-color: transparent;
}

.slidenav button.current {
  color: var(--brand-blue);
  background-color:  rgba(255, 255, 255, .5);

}

.slidenav button:focus {
  border: 2px solid var(--brand-white);
  outline: 2px solid var(--brand-blue);
  outline-offset: -1px;
}

.slidenav button.current:focus {
  border: 2px solid var(--brand-blue);
}

.slide {
  position: relative;
}

.slide-img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
  top: 2em;
  z-index: -1;
}
