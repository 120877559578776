.search-widget {
  grid-area: search;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline-start: 6rem;

  @media (max-width: 60em) {
    padding-inline-start: 0;
    align-items: start;
  }

  &__combobox-container {

    @media (max-width: 20em) {
      margin: .5em;
      box-shadow: rgba(0, 0, 0, .16) 0 6px 12px, rgba(0, 0, 0, .23) 0 6px 12px;
      border-radius: var(--border-radius);
      position: absolute;
      top: 6.5rem;
      left: 0;
      z-index: 10;
    }

    @media (max-width: 40em) {
      z-index: 10;
      position: absolute;
      box-shadow: rgba(0, 0, 0, .16) 0 6px 12px, rgba(0, 0, 0, .23) 0 6px 12px;
      top: 2rem;
      left: 0;
      padding: 0;
      margin: 0;
      right: 2rem;
      width: calc(100vw - 2rem);
      border-radius: var(--border-radius);
    }
  }
}
