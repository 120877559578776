.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  gap: 3rem;
  list-style: none;
  padding: 0;
  margin: 2rem 0;

  &__product {
    padding: 0;
    margin: 0;
    gap: 1rem;
  }

  &__image {
    margin-block-end: .5rem;
    width: 100%;
  }

  &__link {
    text-decoration: none;
    color: var(--brand-blue);

    &::after {
      content: '';
      position: absolute;
      inset: 0;
    }
  }

  &__item-inner {
    position: relative;
  }

  &__select {
    height: 2em;
    font: inherit;
    border: 1px solid var(--brand-blue);
    background-color: transparent;

    @media (max-width: 40em) {
      flex-direction: column;
      gap: 0;
      margin-block-end: 0;
    }
  }

  &__options {
    display: flex;
    justify-content: end;
    gap: 2rem;

    @media (max-width: 40em) {
      display: block;
    }
  }

  &__select {

    @media (max-width: 40em) {
      margin-block-start: .5rem;
    }
  }
}





